import React from "react"
import { graphql } from "gatsby"

//components
import Layout from "../components/Layout"

//constant

const PageTemplate = ({
  data: {
    page: { title, node_locale },
    lang: { nodes },
  },
}) => {
  const langSelect = [
    { locale: nodes[0].node_locale, path: "/" + nodes[0].slug },
    { locale: nodes[1].node_locale, path: "/" + nodes[1].slug },
  ]
  const langCurrent = node_locale

  return (
    <Layout langSelect={langSelect} langCurrent={langCurrent}>
      <div>{title}</div>
    </Layout>
  )
}
export const query = graphql`
  query GetOnePage($slug: String, $lang: String) {
    page: contentfulPage(id: { eq: $slug }) {
      title
      node_locale
      slug
      contentful_id
    }
    lang: allContentfulPage(filter: { contentful_id: { eq: $lang } }) {
      nodes {
        node_locale
        slug
      }
    }
  }
`

export default PageTemplate
